import { useEffect } from 'react';
import { Grid } from "@mui/material";
import SideNote from "../components/SideNote";
import SimpleList from "../components/SimpleList";
import '../App.css';

// Data
import { againstEmpathyAgreementSideNote, defaultEmpathyCaveatsSideNote, empathyCriticismExamples, managementEmpathySideNote, notCoutingOutSideNote, notInvalidatingCasesSideNote, wokeCaveatSideNote } from '../data/EmpathyData';

export const Empathy = ({}) => {
  useEffect(() => {
    document.title = 'In Defense of Empathy and People Who Don’t Have Any';
  }, []);
  return (
    <Grid className="centered-content">
      <div className="container">
        <p className="essay-title">
          In Defense of Empathy and People Who Don’t Have Any
        </p>
        <p className="essay-date">
          1/20/25
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            People are oddly critical of empathy.
          </span>
          <ul className="arrow-list">
              <li className="arrow-list-item">
                <span className="arrow-list-arrow essay-text">➔</span> 
                <span className="essay-text">
                  Some dismiss it because acting purely from empathy leads to worse decisions. It’s possible to lean too much on empathy, just like it’s possible to lean too much on literally every trait, but that doesn’t mean you should reject it completely.
                </span> 
              </li>
              <li className="arrow-list-item">
                <span className="arrow-list-arrow essay-text">➔</span> 
                <span className="essay-text">
                  Others contrast empathy with compassion, instead of recognizing that they’re not mutually exclusive and empathy often amplifies compassion.
                </span> 
              </li>
              <li className="arrow-list-item">
                <span className="arrow-list-arrow essay-text">➔</span> 
                <span className="essay-text">
                  And the author of the book <i>Against Empathy</i> blames empathy for everything up to and including war: “Our empathy for those close to us is a powerful force for war and atrocity toward others.” That’s not empathy, that’s affinity, where a bond to certain people skews our empathy. Having empathy for everyone equally would mean atrocity toward others would be just as unbearable as atrocity toward family. <SideNote note={againstEmpathyAgreementSideNote} isCaption={false} />
                </span> 
              </li>
          </ul>
          <span className="essay-text">
            By feeling some small piece of what other people are feeling, empathy is like magic in tying your own self-interest to other people’s wellbeing.
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            But everyone’s brain works differently. Some people struggle with math and other people find it intuitive. Some people retreat into themselves when they feel strong emotions and other people are outwardly expressive. Some people always put their shopping cart away and other people are way too special for that. There are wide variations in every single trait, so what’s obvious to you might never occur to the people around you. The lenses we go through the world with define what’s right to us but would feel distorted and wrong to other people.
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            For left-wingers, collective responsibility for social justice feels as obvious and necessary as gravity. There’s no perfect word for the personality trait that creates that lens, so the closest description would be: having empathy for people by default, whether or not you have a connection to them. <SideNote note={defaultEmpathyCaveatsSideNote} isCaption={false} /> Some people reading the phrase “having empathy for people by default, whether or not you have a connection to them” will think, <i>of course that’s how my brain works how could you be a good person without that mindset</i>, while other people reading that phrase will think, <i>why would your brain possibly work like that how could you believe it’s right to concern yourself with strangers’ lives</i>. One group can’t <i>not</i> care about people they have no connection to, and the other group would need a strong reason to care about people they have no connection to (for example, something provoking their sense of right and wrong).
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            You can tell when people are in that second group and aren’t wired to feel empathy by default, because they describe woke behaviors as coming from negative intent – being performative, self-righteous, power-hungry, selfish, malevolent, etc. <SideNote note={wokeCaveatSideNote} isCaption={false} /> They ascribe bad intent because they’re making sense of other people’s behaviors based on how their own brain works. From their perspective, the only reason to be woke would be sinister.
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            Intent matters because it changes how we regard people we’re interacting with. It’s possible to work together with someone who sincerely wants to do the right thing and just sees the world differently – you can try to find common ground wherever possible, even if you disagree on most things. But it’s not even worth trying to work with someone whose only goal is to cause harm – they must be defeated. <SideNote note={notCoutingOutSideNote} isCaption={false} /> The intent behind an action doesn’t change the outcome of that action, but the intent signals how someone might be open to changing their future actions and outcomes.
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            Tons of people, probably the vast majority, don’t have an automatic tendency toward empathy. Their empathy seems to be primarily driven by affinity. You can tell when people don’t understand that someone’s empathy can arise mostly from affinity, because they describe anti-woke behaviors as coming from negative intent. For people who don’t have a visceral need to do something about pervasive injustice, telling them that a vote for Trump is a “vote for hate” is like telling the Inuit that they’d be warmer if they lived in Aruba. I do think it’s like lacking a skill, because a tendency toward empathy makes you more in tune with other people’s experiences, but I don’t think it automatically makes you a better or worse person, just like lacking an intuition for math doesn’t automatically make you a better or worse person. Some situations call for more or less empathy, and it’s important to hear from different perspectives. <SideNote note={managementEmpathySideNote} isCaption={false} />
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            And woke people aren’t immune to affinity; the urgency of affinity is how some woke people can be so hateful to the anti-woke. The woke view anti-woke policies as harming people the woke are concerned about, which makes the anti-woke an outgroup that the woke lose all affinity and empathy for. The woke then act hatefully toward the anti-woke, and the anti-woke see this and have the same reaction, where any affinity or empathy they might have had disappears. By treating each other this way, each side guarantees that the other side will work against them, when they could’ve instead guaranteed that some of the other side would work with them.
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            Is it really so hard to believe that people would support trans rights given the <a target="_blank" rel="noopener noreferrer" href="https://ocfcpacourts.us/wp-content/uploads/2022/02/6.-What-Does-the-Scholarly-Research-Say-About-the-Effect-of-Gender-Transition-on-Transgender-Well-Being-What-We-Know.-new.pdf">evidence</a> that it’s healing for someone who’s trans to live as the gender they know they are? <SideNote note={notInvalidatingCasesSideNote} isCaption={false} /> Is it really so hard to believe that people wouldn’t support trans rights because separating gender from biological sex is deeply unfamiliar to them, and they aren’t wired to put enough weight on other people’s experiences to outweigh their own experiences? Is it really so hard to believe that people would pursue a major overhaul of the policing system, after seeing that it’s possible for officers to use a battering ram to enter Breonna Taylor’s apartment at midnight with a <a target="_blank" rel="noopener noreferrer" href="https://www.courier-journal.com/story/news/crime/2022/08/23/breonna-taylor-case-ex-louisville-cop-pleads-guilty-coverup/65406249007/">falsified no-knock warrant</a> for someone who didn't even live there, then firing <a target="_blank" rel="noopener noreferrer" href="https://abcnews.go.com/US/ballistics-report-raises-questions-breonna-taylor-shooting-attorney/story?id=73279097">32 shots</a> and killing her after her boyfriend thought it was robbery and shot one of the officers in the leg? Is it really so hard to believe that people would want to protect the current policing system because they think officers act in self-defense in high-stress situations, and they haven’t had the fraught experiences with police that could make them consider a need for reform?
          </span>
        </p>
        <p className="essay-paragraph">
          <span className="essay-text">
            Those aren’t rhetorical questions – the answer is “no”, none of those should be difficult to believe. But most people are closed off to the possibility that someone could take an opposite position while wanting to do the right thing. Making enemies out of potential allies, we push for what we think is right based only on how our own brains work.
          </span>
        </p>
      </div>
    </Grid>
  )
}
