import React from "react";
import { Card, CardActionArea, CardContent, Grid } from "@mui/material";
import '../App.css';

export const Home = ({}) => {
  return (
    <Grid>
      <div className="section-header">
        <h1 className="section-header-text" style={{marginBottom:'0px'}}>
          Writing
        </h1>
      </div>
      <div className="centered-content vertical-content">
        <Card className="essay-card" variant="outlined">
          <CardActionArea disableRipple className="essay-card-action-area" href="/writing/defense-of-empathy">
            <CardContent className="essay-card-content">
              <h3 className="essay-card-title">
                In Defense of Empathy and People Who Don’t Have Any
              </h3>
              <h5 className="essay-card-subtitle">
                1/20/25
              </h5>
              <h5 className="essay-card-preview">
                <span>
                  People are oddly critical of empathy.
                </span>
                <ul className="arrow-list" style={{marginLeft:'-5px'}}>
                    <li className="arrow-list-item">
                      <span className="arrow-list-arrow">➔</span> 
                      <span>
                        Some dismiss it because acting purely from empathy leads to worse decisions. It’s possible to lean too much on empathy, just like it’s possible to lean too much on literally every trait, but that doesn’t mean you should reject it completely.
                      </span> 
                    </li>
                    <li className="arrow-list-item">
                      <span className="arrow-list-arrow">➔</span> 
                      <span>
                        Others contrast empathy with compassion, instead of recognizing that they’re not mutually exclusive and empathy often amplifies compassion.
                      </span> 
                    </li>
                    <li className="arrow-list-item">
                      <span className="arrow-list-arrow">➔</span> 
                      <span>
                        And the author of the book <i>Against Empathy</i> blames empathy for everything up to and including war: “Our empathy for those close to us is a powerful force for war and atrocity toward others.” That’s not empathy, that’s affinity, where a bond to certain people skews our empathy. Having empathy for everyone equally would mean atrocity toward others would be just as unbearable as atrocity toward family.
                      </span> 
                    </li>
                </ul>
                <span>
                  By feeling some small piece of what other people are feeling, empathy is like magic in tying your own self-interest to other people’s wellbeing.
                </span>
              </h5>
              <h5 className="essay-card-preview">
                <span>
                  But everyone’s brain works differently. Some people struggle with math and other people find it intuitive. Some people retreat into themselves when they feel strong emotions and other people are outwardly expressive. Some people always put their shopping cart away and other people are way too special for that. There are wide variations in every single trait, so what’s obvious to you might never occur to the people around you. The lenses we go through the world with define what’s right to us but would feel distorted and wrong to other people.
                </span>
              </h5>
              <h5 className="essay-card-footnote">
                Continue reading...
              </h5>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card className="essay-card" variant="outlined">
          <CardActionArea disableRipple className="essay-card-action-area" href="/writing/woke-vs-crusty">
            <CardContent className="essay-card-content">
              <h3 className="essay-card-title">
                Woke vs. Crusty
              </h3>
              <h5 className="essay-card-subtitle">
                10/18/24
              </h5>
              <h5 className="essay-card-preview">
                Being “woke” used to mean caring about important societal problems, and under that definition you could apply it to any issue. Just as it would be absurd to say black people and women haven’t been marginalized in the U.S., it would also be absurd to say Republicans haven’t been marginalized by many elite institutions or that men don’t face their own <a href="https://pubmed.ncbi.nlm.nih.gov/39154588/" target="_blank">systemic challenges</a>.
              </h5>
              <h5 className="essay-card-preview">
                The definition has evolved haphazardly, and everyone seems to ignore that it now refers to two different ideas: you can be woke if you have left-wing political views (supporting trans rights, for example), but you can also be woke if you’re intolerant to disagreement (supporting cancel culture, for example). Those two meanings need to be separated into different words. Just because you support trans rights doesn’t mean you support people being canceled for opposing beliefs, but right now people would refer to both cases as “woke”. The first meaning is similar enough to the original usage that it can still fall under “woke”, and the word for the second meaning should be “crusty”.
              </h5>
              <h5 className="essay-card-footnote">
                Continue reading...
              </h5>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card className="essay-card" variant="outlined">
          <CardActionArea disableRipple className="essay-card-action-area" href="/writing/personal-failure-public-health">
            <CardContent className="essay-card-content">
              <h3 className="essay-card-title">
                Cargo Cults, Supply Chains, Personal Failure, and Public Health
              </h3>
              <h5 className="essay-card-subtitle">
                12/31/23
              </h5>
              <h5 className="essay-card-preview">
                Feynman’s cargo cult metaphor describes people who diligently “follow all the apparent precepts and forms of scientific investigation” but don’t deliver anything of value because they aren’t intellectually rigorous:
                <div style={{marginTop:'7px', marginLeft:'25px'}}><i>In the South Seas there is a Cargo Cult of people. During the war they saw airplanes land with lots of good materials, and they want the same thing to happen now. So they’ve arranged to make things like runways, to put fires along the sides of the runways, to make a wooden hut for a man to sit in, with two wooden pieces on his head like headphones and bars of bamboo sticking out like antennas – he’s the controller – and they wait for the airplanes to land. They’re doing everything right. The form is perfect. It looks exactly the way it looked before. But it doesn’t work. No airplanes land.</i></div>
              </h5>
              <h5 className="essay-card-preview">
                When people apply this metaphor to startups, they’re referring to companies that take superficial steps to building a company – raising money, hiring, using trendy technology – without solving an important problem for customers. Just like the South Seas cargo cults do have pieces that help planes land (they have a runway, they have people working at the “airport”, they’re actively building), many of the activities of “cargo cult startups” can be important for creating a successful company, but those efforts are empty if they’re not rooted in making something valuable for customers.
              </h5>
              <h5 className="essay-card-preview">
                The problem with this metaphor is that it sets the bar too low. Most companies make an honest effort at solving an important problem for customers and therefore aren’t cargo cult startups, but most companies still fail.
              </h5>
              <h5 className="essay-card-footnote">
                Continue reading...
              </h5>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card className="essay-card" variant="outlined">
          <CardActionArea disableRipple className="essay-card-action-area" href="/writing/keto">
            <CardContent className="essay-card-content">
              <h3 className="essay-card-title">
                I'm Embarrassed to Say That I've Started the Keto Diet: The Case for Normalizing Keto
              </h3>
              <h5 className="essay-card-subtitle">
                5/22/22
              </h5>
              <h5 className="essay-card-preview">
                The keto diet is a bad joke. It tells us to avoid the carbs our bodies need for fuel and eat fat instead. That means renouncing rice, sandwiches, and noodles, to be replaced with butter, oil, and bacon grease – it’s so twisted that it’s the unhealthiest diet possible while also forbidding the most delicious foods.
              </h5>
              <h5 className="essay-card-footnote">
                Continue reading...
              </h5>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card className="essay-card" variant="outlined">
          <CardActionArea disableRipple className="essay-card-action-area" href="/writing/making-mindfulness-your-default">
            <CardContent className="essay-card-content">
              <h3 className="essay-card-title">
                Making Mindfulness Your Default
              </h3>
              <h5 className="essay-card-subtitle">
                1/1/21
              </h5>
              <h5 className="essay-card-preview">
                On January 1, 2019 I started looking back on my day and recording what I did well, what I did poorly, and what I learned. Steady, incremental progress from two years of this ritual has made such a difference that I barely recognize myself in some of the early entries; certain entries from six months ago even seem like they’re from a completely different person.
              </h5>
              <h5 className="essay-card-footnote">
                Continue reading...
              </h5>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </Grid>
  )
}
