import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../App.css';

import cleanSlateBackground from '../images/CleanSlate/F2VXbs5WEAI03dK.jpg';


export const CleanSlate = ({}) => {
  // Things that should happen on page load
  useEffect(() => {
    // Set the page title
    document.title = 'Clean Slate';
  }, []);

  return (
    // make the "cleanSlateBackground" image take up the whole page as a background
    <div style={{ backgroundImage: `url(${cleanSlateBackground})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Helmet>
        <meta name="robots" content="noindex" /> 
      </Helmet>
      <div className="reminder-message" style={{
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: '#fff',
        padding: '1.5rem',
        borderRadius: '0px',
        textAlign: 'center',
        maxWidth: '600px',
        margin: '0 auto 0rem',
        fontFamily: '"Georgia", serif',
        fontSize: '1.25rem',
        lineHeight: '1.6',
        letterSpacing: '0.5px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        border: '1px solid rgba(255, 255, 255, 0.1)'
      }}>
        Tiny, easy changes, when repeated every day, have huge impacts over time.
      </div>
      
      <div className="flex justify-center cleanslate-container" style={{ flexDirection: 'column' }} >
        {/* <div className="cleanslate-buttons-list">
          
          <button className="cleanslate-button" onClick={() => window.location.href = "/reminders"}>
            Reminders
          </button>
          <button className="cleanslate-button" onClick={() => window.location.href = "https://pong-wars.koenvangilst.nl/"}>
            Mind Wandering
          </button>
          <button className="cleanslate-button" onClick={() => window.location.href = "wiseread://new"}>
            Reader
          </button>
          If it's before 11am or after 10pm local time, also show buttons for hackernews.com, nytimes.com, and espn.com
          {(new Date().getHours() < 11 || new Date().getHours() > 19) && (
            <>
              <button className="cleanslate-button" onClick={() => window.location.href = "https://news.ycombinator.com/"}>
                Hacker News
              </button>
              <button className="cleanslate-button" onClick={() => window.location.href = "https://www.nytimes.com/"}>
                New York Times
              </button>
              <button className="cleanslate-button" onClick={() => window.location.href = "https://www.espn.com/"}>
                ESPN
              </button>
            </>
          )}
        </div> */}
      </div>
    </div>
  )
}
