export const againstEmpathyAgreementSideNote = `<span>I agree with the book’s overall point that “Doing actual good, instead of just doing what feels good, requires dealing with complex issues and…you need to step back and not fall into empathy traps”.</span>`;
export const defaultEmpathyCaveatsSideNote = `<span>Having empathy for a broader range of people doesn’t mean you have less empathy for family and friends. And this doesn’t mean people who aren’t left-wing don’t have empathy, just that having a brain wired with a greater tendency toward empathy seems more likely to make someone resonate with left-wing politics. There are probably plenty of “woke” people with hardly any empathy who have woke beliefs for other reasons, and there are probably plenty of “anti-woke” people full of empathy who are anti-woke for other reasons.</span>`;
export const wokeCaveatSideNote = `<span>Some of those often <i>are</i> entangled in people’s wokeness, as they often are with any political belief, but they’re not at the root of the belief for most people.</span>`;
export const notCoutingOutSideNote = `<span>Or at least that’s how it feels. Based on <a target="_blank" rel="noopener noreferrer" href="https://www.bbc.com/news/stories-54526345"><u>all</u></a> <a target="_blank" rel="noopener noreferrer" href="https://www.cbc.ca/cbcdocspov/features/former-skinhead-now-helps-others-leave-hate-groups"><u>the</u></a> <a target="_blank" rel="noopener noreferrer" href="https://apnews.com/article/690ae31e52674a3c89aed5b7fadd109a"><u>cases</u></a> of white supremacists leaving their hate groups after being treated with compassion, I think most people are capable of change.</span>`;
export const managementEmpathySideNote = `<span>I run into this all the time when giving feedback, because it takes great mental effort to say what people need to hear (causing them minor, fleeting discomfort) for the sake of making everyone better off, especially the person my empathy is telling me I need to protect.</span>`;
export const notInvalidatingCasesSideNote = `<span>While also not invalidating the smaller number of cases of people regretting transitioning.</span>`;
export const transRightsSideNote = `<span>The trans rights movement also shows how people can have the right overall goal, but be wrong in certain policy positions relating to that goal. My belief is that trans women shouldn't compete in women's sports, because their sex gives them an unfair competitive advantage over the other athletes, and competing in sports isn't necessary for living life as a woman. In contrast, I'm completely supportive of trans women winning an Oscar in the best actress category, because there's no competitive advantage.</span>`;
export const takeiTweetSideNote = `<span>There's an easy answer: he's showing an intellectual blindspot. Knowing that the vaccine saves lives made him lose patience for people’s concerns about the vaccine.</span>`;
export const understandReluctanceSideNote = `<span>It’s not like one candidate is always right and the other is always wrong. I understand people’s objections to voting for Harris, given immigration, inflation, international conflict, and more during Biden’s term.</span>`;
export const moreTrumpCrustyExamplesSideNote = `<span>Other examples include waiting <a target="_blank" rel="noopener noreferrer" href="https://www.foxnews.com/video/6309950724112">over three hours</a> before telling the people invading the Capitol Building (and killing a police officer) to go home, leaving the country unprepared for covid by <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/live/NbwCjL7HC1c?si=DLoHVpJuSY-ABS3j&amp;t=353">denying the danger it posed</a>, insisting <a target="_blank" rel="noopener noreferrer" href="https://rumble.com/v11w8xm-president-donald-trump-delivers-keynote-speech-in-florida.html?start=3100">the impact of climate change is a hoax</a> despite storms getting more destructive every year, wanting to make it possible to <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=EUoqbbneOFc">sue news organizations</a> for being critical of him, <a target="_blank" rel="noopener noreferrer" href="https://www.pbs.org/newshour/politics/photos-from-trump-indictment-show-boxes-of-classified-documents-stored-in-mar-a-lago-shower-ballroom">hoarding top-secret documents</a> in public places in his home, <a target="_blank" rel="noopener noreferrer" href="https://www.foxnews.com/politics/obamacare-straight-repeal-fails-on-senate-vote">attempting to repeal</a> Obamacare, causing the <a target="_blank" rel="noopener noreferrer" href="https://www.reuters.com/article/world/backing-down-trump-agrees-to-end-shutdown-without-border-wall-money-idUSKCN1PJ2DJ">longest government shutdown</a> in U.S. history, and filing for bankruptcy <a target="_blank" rel="noopener noreferrer" href="https://www.politifact.com/factchecks/2016/jun/21/hillary-clinton/yep-donald-trumps-companies-have-declared-bankrupt/">six times</a>.</span>`;
export const laptopMisinformationSideNote = `<span>JD Vance has spread the misinformation that censoring the Hunter Biden laptop story cost Trump millions of votes. That number comes from a partisan <a target="_blank" rel="noopener noreferrer" href="https://www.mrc.org/">team</a> (their homepage says, “MRC leads the conservative movement in combatting the left’s efforts to manipulate the electoral process”) who didn't even ask specifically about Hunter Biden's emails in their <a target="_blank" rel="noopener noreferrer" href="https://cdn.mrc.org/TPC-MRC+Biden+Voter+Messaging+Survey+Analysis+Nov+2020_final.pdf/">survey</a>. It would be like a Democrat staking all their credibility on a biased MSNBC poll.</span>`;
export const respectingChoiceSideNote = `<span>I hope you can tell by now that I really mean that.</span>`;
export const peopleIAgreeWithSideNote = `<span>This obviously also goes for people who I agree with – for people who want to protect abortion rights and who want to keep the lizards in power, I support them voting for Harris. However, for friends who want to reduce the death and suffering of Palestinians, I don’t support them voting for Trump or choosing not to vote. It goes against their values, because a Trump presidency would only lead to more death and suffering of Palestinians. I understand that the goal is to send a message to Democrats, but it sends an even stronger message to Republican politicians that their destructive policies can continue. The same applies for reducing the death and suffering of Israelis.</span>`;
export const notCriticizingSupportersSideNote = `<span>My criticisms are directed at Trump, not his supporters.</span>`;

export const crustyExamples = [
  `<span>Not accepting evidence that goes against your beliefs</span>`,
  `<span>Spreading misinformation</span>`,
  `<span>Not accepting any nuance</span>`,
  `<span>Not taking into account the impact of your actions, regardless of how good your intentions are</span>`,
  `<span>Not giving people the benefit of the doubt</span>`,
  `<span>Mischaracterizing other groups of people</span>`,
  `<span>Treating people who disagree with you as if they don’t deserve respect (insults, ostracism, inconsistent standards, etc.)</span>`
]

export const previewToTrumpExamples = [
  `Preventing Democrats from securing the border`,
  `Imposing tariffs that will make everything more expensive again just as inflation is <a target="_blank" rel="noopener noreferrer" href="https://www.bls.gov/charts/consumer-price-index/consumer-price-index-by-category-line-chart.htm">back under control</a>`,
  `Escalating conflicts in the Middle East`,
]

export const empathyCriticismExamples = [
  `Some dismiss it because acting purely from empathy leads to worse decisions. It’s possible to lean too much on empathy, just like it’s possible to lean too much on literally every trait, but that doesn’t mean you should reject it completely.`,
  `Others contrast empathy with compassion, instead of recognizing that they’re not mutually exclusive and empathy often amplifies compassion.`,
  `He declared Jerusalem as the capital of Israel, leading to Hamas calling for renewed violence against Israel, as well as the U.S.-backed Palestinian president saying the U.S. could <a target="_blank" rel="noopener noreferrer" href="https://www.fec.gov/resources/cms-content/documents/federalelections2020.pdf">no longer mediate</a> peace efforts.`,
]